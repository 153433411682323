<template>
  <v-container
    class="outer-wrapper fluid d-flex flex-column justify-center align-center pa-0 ma-0"
    :style="
      isLogin
        ? {
            backgroundImage:
              'url(' + require('@/assets/images/bg_bottom.svg') + ')',
          }
        : {}
    "
  >
    <v-col
      v-if="isLogin"
      cols="12"
      class="waves pa-0 ma-0"
      :style="{
        backgroundImage: 'url(' + require('@/assets/images/top.svg') + ')',
      }"
      ><p></p
    ></v-col>
    <AppHeader v-if="!isLogin" />
    <slot class="admin-layout-main" />
    <ScrollUpButton />
    <AdminFooter />
  </v-container>
</template>

<script lang="ts">
import AppHeader from '../admin/header/AppHeader.vue';
import ScrollUpButton from '../base/ScrollUpButton.vue';
import Component from 'vue-class-component';
import AdminFooter from '../admin/base/AdminFooter.vue';
import SchoolMixin from './SchoolMixin';

@Component({
  components: { AdminFooter, ScrollUpButton, AppHeader },
  name: 'AdminLayout',
})
export default class AdminLayout extends SchoolMixin {
  get backgroundImage(): string {
    return require('@/assets/images/bg_top.svg');
  }

  get isLogin(): boolean {
    return (
      this.$route.name === 'school' ||
      this.$route.name === 'forgotPassword' ||
      this.$route.name === 'resetPassword' ||
      this.$route.name === 'imprint'
    );
  }
}
</script>

<style scoped lang="scss">
.outer-wrapper {
  background-color: $background;
  max-width: 100% !important;
  background-size: cover;
  background-position: bottom center;
  min-height: 100vh;
}

.waves {
  width: 100%;
  background-size: contain repeat;

  p {
    height: 150px;
  }
}
</style>
