<template>
  <v-col cols="12" class="manage-classes">
    <v-row class="ma-0 justify-center">
      <v-col v-if="isSuperadminRoute" cols="12" class="py-0 underline">
        <h4 class="text-h5 mb-1">{{ $t('manageClass.title') }}</h4>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-center justify-md-center align-center mb-4"
      >
        <v-btn color="primary" @click="openAddEditDialog">
          <v-icon class="mr-2">mdi-plus-circle</v-icon>
          <p class="ma-0 mb-n1">{{ $t('manageClass.add.title') }}</p>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="9"
        class="schoolclass-border"
        v-for="c in schoolClasses"
        :key="c.id"
      >
        <v-row class="ma-0 justify-space-between align-center">
          <v-col cols="12" sm="12" md="6" class="pa-0 pb-5 pb-md-0">
            <p class="text-body-1 ma-0 text-center text-md-start">
              {{
                c.name +
                ' ' +
                `(${$t('client.students')}: ${c.students?.length} )`
              }}
            </p>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            class="pa-0 d-flex justify-space-between justify-md-end flex-wrap align-center"
          >
            <v-btn
              class="mx-2"
              small
              color="primary"
              dark
              @click="openAddEditDialog(c)"
            >
              <v-icon>mdi-pencil</v-icon>
              <p class="ma-0 mb-n1">{{ $t('manageClass.edit.button') }}</p>
            </v-btn>
            <v-btn small color="black" dark @click="openDeleteDialog(c)">
              <v-icon>mdi-delete</v-icon>
              <p class="ma-0 mb-n1">{{ $t('manageClass.delete.button') }}</p>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!--{# DELETE DIALOG #}-->
      <v-dialog v-model="showDeleteDialog" max-width="600px" persistent>
        <v-card class="pa-2">
          <v-card-title class="d-flex justify-center align-center">
            <v-icon class="mr-2" color="warning">mdi-alert</v-icon>
            <p class="text-center text-h4 font-weight-bold ma-0 mb-1">
              {{ $t('attention') }}
            </p>
            <v-icon class="ml-2" color="warning">mdi-alert</v-icon>
          </v-card-title>
          <v-card-text>
            <p class="text-h6 font-weight-bold mt-2 text-center">
              {{ $t('manageClass.delete.info', { name: chosenClass?.name }) }}
            </p>
            <p class="text-body-1 text-center">
              {{ $t('manageClass.delete.info2') }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              data-testid="cancel-delete-client-button"
              color="blue darken-1"
              text
              @click="cancel"
            >
              <p class="ma-0">{{ $t('cancel') }}</p>
            </v-btn>
            <v-btn
              data-testid="delete-students-button"
              color="error"
              :loading="loadingDelete"
              @click="deleteClass(chosenClass)"
            >
              <p class="ma-0">{{ $t('delete') }}</p>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <AddEditClass
        :show="showAddEditDialog"
        :schoolId="schoolId"
        :school-class="chosenClass"
        :class-limit="classLimit"
        :class-count="schoolClasses?.length"
        @edited="onUpdate"
        @close="cancel"
      />
      <v-col cols="12" class="d-flex justify-end"> </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import { GetSchoolClassDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AddEditClass from './AddEditClass.vue';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';

@Component({
  components: { AddEditClass },
})
export default class ManageClasses extends Vue {
  clientModule: ClientModule = getModule(ClientModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  @Prop()
  schoolId!: string;

  @Prop()
  classes?: GetSchoolClassDto[];

  @Prop()
  classLimit!: number;

  schoolClasses: GetSchoolClassDto[] = [];
  chosenClass: GetSchoolClassDto | null = null;

  showAddEditDialog = false;
  showDeleteDialog = false;
  loadingDelete = false;

  get isSuperadminRoute() {
    return this.$route.name === 'groups-detail';
  }

  async fetchClasses() {
    try {
      this.schoolClasses = await this.clientModule.getCurrentClasses(
        this.schoolId,
      );
    } catch (e) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageClass.fetch.error').toString(),
        isSuccess: false,
      });
    }
  }

  async mounted() {
    if (this.classes && this.classes?.length > 0) {
      this.schoolClasses = this.classes;
    } else {
      await this.fetchClasses();
    }
  }

  async deleteClass(classToDelete?: GetSchoolClassDto | null) {
    if (!classToDelete) return;
    try {
      this.loadingDelete = true;
      await this.clientModule.deleteAllStudentsOfClass({
        schoolId: this.schoolId,
        grade: classToDelete.name,
      });
      // INFO refetch own info if logged in as admin
      const hasAdminRights = this.schoolModule.adminIds.includes(
      this.userModule.currentUser?._id,
    );
    if (hasAdminRights) {
      await this.userModule.whoAmI(this.schoolModule.schoolId!);
    }
      this.onUpdate();
      this.cancel();
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageClass.delete.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageClass.delete.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingDelete = false;
    }
  }

  openAddEditDialog(schoolClass?: GetSchoolClassDto) {
    this.showAddEditDialog = true;
    // INFO prevent pointer event from being schoolclass
    if (schoolClass?.name) {
      this.chosenClass = schoolClass;
    }
  }

  openDeleteDialog(classToDelete: GetSchoolClassDto) {
    if (classToDelete?.name) {
      this.chosenClass = classToDelete;
      this.showDeleteDialog = true;
    }
  }

  cancel() {
    this.showAddEditDialog = false;
    this.showDeleteDialog = false;
    this.chosenClass = null;
  }

  @Emit()
  async onUpdate() {
    await this.fetchClasses();

  }
}
</script>

<style lang="scss" scoped>
.schoolclass-border {
  border-bottom: 1px solid #e0e0e0;
}
</style>
