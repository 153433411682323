<template>
  <v-container>
    <v-col class="addClientForm my-5" cols="12">
      <v-card class="pa-2">
        <v-form @submit.prevent="addClient">
          <v-row class="justify-start">
            <v-col cols="12" class="d-flex justify-center">
              <p class="text-h6">{{ $t('client.new-client') }}</p>
            </v-col>
            <v-col cols="12">
              <v-row class="ma-0" justify="center">
                <v-radio-group
                  row
                  v-model="clientType"
                  class="ml-5 justify-self-center"
                  data-testid="form-clientType"
                >
                  <v-radio
                    v-for="(t, i) in types"
                    :key="i"
                    :label="t.name"
                    :value="t.type"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="schoolName"
                data-testid="form-schoolName"
                :error-messages="schoolNameErrors"
                :label="$t('client.property.name') + '*'"
                dense
                outlined
                required
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="slug"
                data-testid="form-slug"
                :error-messages="slugErrors"
                :label="$t('client.property.slug') + '*'"
                dense
                outlined
                required
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="schoolId"
                data-testid="form-schoolId"
                :error-messages="schoolIdErrors"
                :label="$t('client.property.id') + '*'"
                dense
                outlined
                type="number"
                clearable
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="street"
                data-testid="form-street"
                :error-messages="streetErrors"
                :label="$t('client.property.street') + '*'"
                dense
                outlined
                required
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="postcode"
                data-testid="form-postcode"
                :error-messages="postcodeErrors"
                :label="$t('client.property.postcode') + '*'"
                dense
                outlined
                min="1000"
                type="number"
                clearable
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="city"
                data-testid="form-city"
                :error-messages="cityErrors"
                :label="$t('client.property.city') + '*'"
                dense
                outlined
                clearable
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="country"
                data-testid="form-country"
                :items="countries"
                :label="$t('client.property.country') + '*'"
                :search-input="search"
                autocomplete="new-password"
                class="py-0"
                outlined
                clearable
                dense
                background-color="white"
                item-text="name"
                item-value="code"
                :validate-on-blur="false"
                :error-messages="countryErrors"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="licenseUntil"
                v-mask="'##.##.####'"
                data-testid="form-licenseUntil"
                :validate-on-blur="false"
                :label="`${$t('client.property.licenseUntil')}*`"
                name="licenseUntil"
                :error-messages="licenseUntilErrors"
                outlined
                clearable
                dense
                prepend-inner-icon="mdi-calendar-month"
              ></v-text-field>
            </v-col>
            <v-col v-if="isSchoolClient" cols="12" md="6" class="py-0">
              <v-text-field
                v-model="classLimit"
                data-testid="edit-classLimit"
                :error-messages="classLimitErrors"
                :label="$t('client.property.classLimit') + '*'"
                dense
                outlined
                min="8"
                type="number"
                clearable
                required
                @input="$v.classLimit.$touch()"
                @blur="$v.classLimit.$touch()"
              ></v-text-field>
            </v-col>
            <v-col v-if="isTherapyClient" cols="12" md="6" class="py-0">
              <v-text-field
                v-model="validTests"
                data-testid="form-validTests"
                :error-messages="validTestsErrors"
                :label="$t('client.property.validTests') + '*'"
                dense
                outlined
                type="number"
                clearable
                required
                min="1"
                @input="$v.validTests.$touch()"
                @blur="$v.validTests.$touch()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                data-testid="add-client-button"
                color="primary"
                :loading="loading"
                type="submit"
              >
                <v-icon>mdi-account-plus</v-icon>
                <p class="ma-0 mb-n1 ml-2">{{ $t('client.add') }}</p></v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-col>
    <AdminSnackbar />
  </v-container>
</template>

<script lang="ts">
import { Client, CreateSchoolDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import * as allCountries from 'i18n-iso-countries';
import { Component, Vue } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import {
  maxLength,
  minValue,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import AdminSnackbar from '../admin/base/AdminSnackbar.vue';
// eslint-disable-next-line @typescript-eslint/no-var-requires
allCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const hasNoLeadingZero = (value: any) => {
  if (!value) return true;
  return value[0] !== '0';
};

export const isValidDate = (value: any) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  // https://stackoverflow.com/questions/51224/regular-expression-to-match-valid-dates comment: Harendra Chauhan
  return /(((0[1-9]|[12][0-9]|3[01])([.])(0[13578]|10|12)([.])(\d{4}))|(([0][1-9]|[12][0-9]|30)([.])(0[469]|11)([.])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([.])(02)([.])(\d{4}))|((29)(\/)(02)([.])([02468][048]00))|((29)([.])(02)([.])([13579][26]00))|((29)([.])(02)([.])([0-9][0-9][0][48]))|((29)([.])(02)([.])([0-9][0-9][2468][048]))|((29)([.])(02)([.])([0-9][0-9][13579][26])))/.test(
    value,
  );
};

@Component({
  components: { AdminSnackbar },
  mixins: [validationMixin],
  validations: {
    clientType: {
      required,
    },
    schoolId: {
      required,
      hasNoLeadingZero,
    },
    schoolName: {
      required,
    },
    slug: {
      required,
    },
    postcode: {
      required,
      minValue: minValue(1000),
    },
    street: {
      required,
    },
    city: {
      required,
    },
    country: {
      required,
    },
    licenseUntil: {
      required,
      isValidDate,
      maxLength: maxLength(10),
    },
    classLimit: {
      required: requiredIf(function (this: any) {
        return this.clientType === Client.school;
      }),
      minValue: minValue(8),
    },
    validTests: {
      required: requiredIf(function (this: any) {
        return this.clientType === Client.therapy;
      }),
    },
  },
})
export default class AddEditGroup extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  clientModule: ClientModule = getModule(ClientModule, this.$store);

  types = [
    { name: 'Schule', type: Client.school },
    { name: 'Therapie', type: Client.therapy },
    { name: 'Eltern', type: Client.parent },
  ];

  homeCountry = 'Österreich';
  firstCountries = ['Deutschland', 'Schweiz'];

  countrySort = (a: string, b: string) => {
    if (a === this.homeCountry) {
      return -1;
    } else if (b === this.homeCountry) {
      return 1;
    }
    if (this.firstCountries.includes(a) && this.firstCountries.includes(b)) {
      return a.localeCompare(b);
    } else if (this.firstCountries.includes(a)) {
      return -1;
    } else if (this.firstCountries.includes(b)) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  };

  countries = Object.values(allCountries.getNames('de')).sort(this.countrySort);
  search = '';

  id: string | null = null;

  loading = false;

  clientType = Client.school;
  schoolId: string | null = null;
  schoolName: string | null = null;
  street: string | null = null;
  postcode: number | null = null;
  city: string | null = null;
  country: string | null = null;

  licenseUntil: string | null = null;

  validTests: number | null = null;
  slug: string | null = null;
  classLimit: number | null = null;

  get isTherapyClient(): boolean {
    return this.clientType === Client.therapy;
  }

  get isSchoolClient(): boolean {
    return this.clientType === Client.school;
  }

  async addClient() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        const client: CreateSchoolDto = {
          clientType: this.clientType,
          schoolId: this.schoolId!,
          schoolName: this.schoolName!,
          street: this.street!,
          postcode: this.postcode!.toString(),
          city: this.city!,
          country: allCountries.getAlpha2Code(this.country!, 'de')!,
          licenseUntil: this.licenseUntil!.split('.').reverse().join('-'),
          validTests: this.validTests ? +this.validTests : undefined,
          slug: this.slug!,
          url: `${window.location.origin}/#/${this.slug!}`,
          isTutorialOnly: false,
          classLimit: this.classLimit ? +this.classLimit : undefined,
        };
        await this.clientModule.addClient(client);
        this.snackbarModule.showMessage({
          message: this.$i18n.t('client.snackbar.add.success').toString(),
          isSuccess: true,
        });
        const schoolId = this.schoolId;
        this.clear();
        await this.clientModule.fetchAllClients();
        // go to detail page of new group
        this.$router.push({
          name: 'groups-detail',
          params: { id: schoolId! },
        });
      } catch (e: any) {
        const error = e.response?.data?.message;
        if (error.includes('School with this schoolId already exists')) {
          this.snackbarModule.showMessage({
            message: this.$i18n
              .t('client.snackbar.add.conflictError')
              .toString(),
            isSuccess: false,
          });
        } else if (error.includes('School with this slug already exists')) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.add.slugError').toString(),
            isSuccess: false,
          });
        } else if (error.includes('Admin Email already registered')) {
          this.snackbarModule.showMessage({
            message: this.$i18n
              .t('client.snackbar.add.adminEmailError')
              .toString(),
            isSuccess: false,
          });
        } else if (error.includes('could not create admin for')) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.add.adminError').toString(),
            isSuccess: false,
          });
          await this.clientModule.fetchAllClients();
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.add.error').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  get schoolNameErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.name').toString();
    if (!this.$v.schoolName.$dirty) return errors;
    !this.$v.schoolName.required && errors.push(requiredError);
    return errors;
  }

  get schoolIdErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.id').toString();
    const zeroError = this.$t('client.form.zero').toString();
    if (!this.$v.schoolId.$dirty) return errors;
    !this.$v.schoolId.hasNoLeadingZero && errors.push(zeroError);
    !this.$v.schoolId.required && errors.push(requiredError);
    return errors;
  }

  get streetErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.street').toString();
    if (!this.$v.street.$dirty) return errors;
    !this.$v.street.required && errors.push(requiredError);
    return errors;
  }

  get postcodeErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.postcode').toString();
    const minValueError = this.$t('client.form.minValue').toString();
    if (!this.$v.postcode.$dirty) return errors;
    !this.$v.postcode.minValue && errors.push(minValueError);
    !this.$v.postcode.required && errors.push(requiredError);
    return errors;
  }

  get cityErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.city').toString();
    if (!this.$v.city.$dirty) return errors;
    !this.$v.city.required && errors.push(requiredError);
    return errors;
  }

  get countryErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.country').toString();
    if (!this.$v.country.$dirty) return errors;
    !this.$v.country.required && errors.push(requiredError);
    return errors;
  }

  get licenseUntilErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.licenseUntil').toString();
    const validDateError = this.$t('client.form.invalidDate').toString();
    const maxLengthError = this.$t('client.form.maxLength').toString();
    if (!this.$v.licenseUntil.$dirty) return errors;
    !this.$v.licenseUntil.maxLength && errors.push(maxLengthError);
    !this.$v.licenseUntil.isValidDate && errors.push(validDateError);
    !this.$v.licenseUntil.required && errors.push(requiredError);
    return errors;
  }

  get classLimitErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.classLimit').toString();
    const minValueError = this.$t('client.form.classLimitMin').toString();
    if (!this.$v.classLimit.$dirty) return errors;
    !this.$v.classLimit.minValue && errors.push(minValueError);
    !this.$v.classLimit.required && errors.push(requiredError);
    return errors;
  }

  get validTestsErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.validTests').toString();
    if (!this.$v.validTests.$dirty) return errors;
    !this.$v.validTests.required && errors.push(requiredError);
    return errors;
  }

  get slugErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.slug').toString();
    if (!this.$v.slug.$dirty) return errors;
    !this.$v.slug.required && errors.push(requiredError);
    return errors;
  }

  clear() {
    this.$v.$reset();

    this.clientType = Client.school;
    this.schoolId = null;
    this.schoolName = null;
    this.street = null;
    this.postcode = null;
    this.city = null;
    this.country = null;
    this.licenseUntil = null;
    this.validTests = null;
    this.slug = null;
    this.classLimit = null;
  }
}
</script>

<style lang="scss" scoped>
.addClientForm {
  min-height: 80vh;
}
</style>
