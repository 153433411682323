import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component({})
export default class SchoolMixin extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  isWrongUrl = false;
  interval: any;
  retries = 0;

  async checkForInterval() {
    // INFO set interval to retry fetching info if schoolInfo are not found
    const schoolName = this.$route.params.schoolName;
    if (
      schoolName !== this.schoolModule.school.slug ||
      this.schoolModule.school?.classes === undefined ||
      this.schoolModule.school?.classes?.length === 0
    ) {
      if (!this.interval) {
        this.interval = setInterval(async () => {
          await this.getTheSchoolInfo();
          this.retries++;
        }, 5000);
      }
    }
  }

  clear() {
    if (this.interval) {
      clearInterval(this.interval);
      this.retries = 0;
      this.interval = null;
      // console.log('[CLEARED INTERVAL]');
    }
  }

  @Watch('$route.params.schoolName')
  async onSchoolNameChanged(curr: string, prev: string) {
    const schoolId = this.$route.params.schoolName;
    if (schoolId && curr !== prev) {
      sessionStorage.setItem('schoolId', schoolId.toString());
      await this.getTheSchoolInfo();
      await this.checkForInterval();
    }
  }

  async getTheSchoolInfo() {
    const schoolName = this.$route.params.schoolName;
    if (schoolName) {
      try {
        await this.schoolModule.getBasicSchoolInfo(schoolName);

        if (
          this.$route.params.schoolName === this.schoolModule.school.slug &&
          this.schoolModule.school?.classes?.length > 0
        ) {
          this.clear();
        }
      } catch (error: any) {
        console.log("[ERROR] Couldn't fetch school info", error);
        if (
          error.response?.data.message.includes(
            'School does not exist with this slug',
          )
        ) {
          // INFO in case of old school data in store clear it
          // old data would show available classes in input field even if the current school info is not found
          // this only applies if url was correctly set before and they would change the address to a wrong one
          this.schoolModule.school.classes = [];
          this.schoolModule.school.name = 'Unbekannte Adresse';

          // INFO dont show message on every retry
          if (this.retries === 0 || this.retries % 4 === 0) {
            this.snackbarModule.showMessage({
              message:
                'URL unbekannt! Bitte überprüfen Sie die Adresse und laden Sie die Seite neu.',
              isSuccess: false,
            });
          }
        }
      }
    }
  }

  async mounted(): Promise<void> {
    const hasSchoolId = sessionStorage.getItem('schoolId');
    const isStudentOrAdminLogin =
      this.$route.name === 'school' || this.$route.name === 'login';
    if (isStudentOrAdminLogin || !hasSchoolId) {
      const schoolId = this.$route.params.schoolName;
      if (schoolId) {
        sessionStorage.setItem('schoolId', schoolId.toString());
        await this.getTheSchoolInfo();
        await this.checkForInterval();
      }
    }
  }

  beforeDestroy() {
    this.clear();
  }
}
