<template>
  <v-container class="outer-wrapper fluid fill-height ma-0 pa-0 align-start">
    <v-row class="layout__outer ma-0 pa-0 align-start">
      <v-img
        v-if="showLeaf"
        class="leaf leaf__left"
        :src="leaf"
        alt="Blatt"
        eager
      />
      <v-img
        v-if="showLeaf"
        class="leaf leaf__right"
        :src="leaf"
        alt="Blatt"
        eager
      />
      <v-col
        class="layout__header ma-0 pa-0 d-flex justify-center align-start"
        cols="12"
      >
        <slot name="header" />
      </v-col>

      <v-col
        class="layout__main pa-0 d-flex justify-center align-start pa-0"
        cols="12"
        :class="isLogin ? 'mainHeight' : 'mainTestHeight'"
      >
        <slot class="main-content" name="main" />
      </v-col>
      <v-col
        cols="12"
        class="layout__footer pa-0 d-flex align-end align-self-end"
      >
        <BaseFooter />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Test } from '../../api/types/api';
import BaseFooter from '../base/BaseFooter.vue';
import SchoolMixin from './SchoolMixin';

@Component({
  components: { BaseFooter },
  name: 'GeneralLayout',
})
export default class GeneralLayout extends SchoolMixin {
  @Prop() header?: string;

  get leaf(): string {
    return require('@/assets/images/palm_leaf.png');
  }

  get isSyllable(): boolean {
    return this.$route.name === Test.syllables;
  }

  get isLogin(): boolean {
    return this.$route.name === 'login';
  }

  get showLeaf(): boolean {
    let isOnTestRoute =
      this.$route.name === Test.letters ||
      this.$route.name === Test.fantasy ||
      this.$route.name === Test.syllables ||
      this.$route.name === Test.words ||
      this.$route.name === Test.riddle;
    return !isOnTestRoute;
  }
}
</script>

<style scoped lang="scss">
.outer-wrapper {
  margin: 0;
  max-width: 100vw;
  min-height: 100vh;
  background: rgb(2, 0, 36);
  background: rgb(49, 37, 112);
  background: linear-gradient(
    209deg,
    rgba(49, 37, 112, 1) 0%,
    rgba(49, 126, 164, 1) 35%,
    rgba(47, 190, 199, 1) 100%
  );
}

.layout {
  &__outer {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  &__header {
    // max-height: 22vh;
    // flex-shrink: 1;
  }

  &__main {
    // flex: 1;
  }

  &__footer {
    max-height: 50px;
  }
}

.mainHeight {
  min-height: 70vh;
}

.mainTestHeight {
  min-height: 70vh;
}

.leaf {
  position: absolute;
  max-width: 30%;
  height: auto;
  z-index: 100;

  &__left {
    transform: scaleX(-1) rotate(50deg);
    top: 10px;
    right: 80%;
  }

  &__right {
    transform: scaleX(-1) rotate(-45deg);
    top: 130px;
    right: -150px;
  }
}
// .leaf {
//   position: absolute;
//   max-width: 30%;
//   max-height: 350%;
//   width: 60%;
//   height: auto;
//   overflow: hidden;

//   &__left {
//     transform: scaleX(-1) rotate(50deg);
//     top: 10px;
//     right: 80%;
//   }

//   &__right {
//     transform: scaleX(-1) rotate(-45deg);
//     top: 130px;
//     right: -150px;
//   }
// }
</style>
