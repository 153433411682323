<template>
  <v-col cols="12" class="add-admin">
    <v-form @submit.prevent="addExistingAsAdmin">
      <v-row>
        <v-col cols="12">
          <p class="font-weight-bold">
            {{ $t('client.existing-user-as-admin') }}
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="9" xl="9">
          <v-text-field
            v-model="email"
            data-testid="form-adminEmail"
            :error-messages="adminEmailErrors"
            :label="$t('client.property.adminEmail')"
            dense
            outlined
            clearable
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
          <v-btn :loading="loading" block type="submit" color="primary"
            ><v-icon class="mr-2">mdi-account-key</v-icon
            >{{ $t('client.add-admin-rights') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script lang="ts">
import ClientModule from '@/store/modules/ClientModule';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';

@Component({
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email,
    },
  },
})
export default class AddExistingAsAdmin extends Vue {
  @Prop()
  schoolId!: string;

  clientModule: ClientModule = getModule(ClientModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  loading = false;
  email: string | null = null;

  async addExistingAsAdmin() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      try {
        this.loading = true;

        const user = await this.schoolModule.findOneUser(this.email!);

        await this.clientModule.addOrRemoveAsAdmin({
          schoolId: this.schoolId,
          adminDto: {
            keycloakId: user?._id,
          },
        });
        this.onUpdate();
        this.clear();
      } catch (error: any) {
        if (error?.message?.includes('404')) {
          this.snackbarModule.showMessage({
            message: this.$i18n
              .t('client.snackbar.admin.no-user-found')
              .toString(),
            isSuccess: false,
          });
        } else if (
          error?.response?.data?.message?.includes(
            'User is already a therapy member',
          )
        ) {
          this.snackbarModule.showMessage({
            message: this.$i18n
              .t('client.snackbar.admin.no-therapy-member-as-admin')
              .toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.admin.addError').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  clear() {
    this.email = null;
    this.$v.$reset();
  }

  @Emit()
  onUpdate() {
    return true;
  }

  get adminEmailErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminEmail').toString();
    const emailError = this.$t('client.form.invalidEmail').toString();
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push(emailError);
    !this.$v.email.required && errors.push(requiredError);
    return errors;
  }
}
</script>

<style lang="scss" scoped>
// Your SCSS styles go here
</style>
