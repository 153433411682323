<template>
  <v-btn :loading="loading" color="primary" @click="importToMongoDb">
    <v-icon class="mr-2">mdi-application-import</v-icon>
    <p class="ma-0 mb-n1">{{ $t('mongo.import.button') }}</p>
  </v-btn>
</template>

<script lang="ts">
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';

@Component({ components: {} })
export default class ImportToMongoButton extends Vue {
  clientModule: ClientModule = getModule(ClientModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  loading = false;

  async importToMongoDb() {
    try {
      this.loading = true;
      await this.clientModule.importToMongoDb();
      this.snackbarModule.showMessage({
        message: this.$i18n.t('mongo.import.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      console.error(error);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('mongo.import.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loading = false;
    }
  }
}
</script>
