<template>
  <v-col cols="12" class="add-admin">
    <v-form @submit.prevent="addAdmin">
      <v-row class="ma-0">
        <v-col cols="12" class="d-flex py-0 underline">
          <p class="text-h5 mb-1">{{ $t('client.admin') }}</p>
        </v-col>
        <v-col v-if="hasNoAdmin" cols="12" class="d-flex py-0">
          <p class="error--text text-h5 font-weight-bold">
            {{ $t('client.noAdmin') }}
          </p>
        </v-col>
        <v-row cols="12" class="d-flex py-0 ma-0 mb-6">
          <v-col
            v-for="a in admins"
            :key="a._id"
            cols="12"
            justify="space-between"
            class="ma-0 align-center admins-row"
          >
            <v-col cols="12" class="pa-0 d-flex justify-space-between">
              <p class="text-body-1 font-weight-bold ma-0">
                {{ a.firstname + ' ' + a.lastname + ' (' + a.email + ')' }}
              </p>
              <v-col
                cols="12"
                sm="6"
                class="d-flex justify-space-between justify-sm-end pa-0"
              >
                <v-btn
                  v-if="isSchool"
                  small
                  :loading="loadingAdminUpdate && chosenId === a._id"
                  @click="addOrRemoveAsAdmin(a._id)"
                >
                  <v-icon class="mr-2">mdi-account-minus</v-icon
                  >{{ $t('client.removeAdmin') }}</v-btn
                >
                <v-btn
                  v-if="isSchool"
                  small
                  color="black"
                  dark
                  :loading="loadingAdminRemove && chosenId === a._id"
                  @click="removeFromSchool(a._id)"
                  class="ml-4"
                >
                  <v-icon class="mr-2">mdi-home-remove-outline</v-icon>
                  <p class="ma-0">{{ $t('client.removeFromSchool') }}</p></v-btn
                >
              </v-col>
            </v-col>
          </v-col>
        </v-row>
        <AddExistingAsAdmin
          v-if="isSchool"
          :school-id="schoolId"
          @on-update="onUpdate"
        />

        <template v-if="isSchool || hasNoAdmin">
          <v-col cols="12">
            <p class="font-weight-bold">{{ $t('client.admin-user-create') }}</p>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="adminFirstname"
              data-testid="form-adminFirstname"
              :error-messages="adminFirstnameErrors"
              :label="$t('client.property.adminFirstname')"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="adminLastname"
              data-testid="form-adminLastname"
              :error-messages="adminLastnameErrors"
              :label="$t('client.property.adminLastname')"
              dense
              outlined
              clearable
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="adminEmail"
              data-testid="form-adminEmail"
              :error-messages="adminEmailErrors"
              :label="$t('client.property.adminEmail')"
              dense
              outlined
              clearable
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="adminPassword"
              data-testid="form-adminPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :error-messages="adminPasswordErrors"
              :label="$t('client.property.adminPassword')"
              dense
              name="password"
              autocomplete="new-password"
              outlined
              clearable
              required
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="confirmPassword"
              data-testid="form-confirmPassword"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmPassword ? 'text' : 'password'"
              :error-messages="confirmPasswordErrors"
              :label="$t('client.property.confirmPassword')"
              dense
              name="new-password"
              autocomplete="new-password"
              outlined
              clearable
              required
              @click:append="showConfirmPassword = !showConfirmPassword"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn color="primary" :loading="loadingNewAdmin" type="submit">
              <v-icon class="mr-2">mdi-account-plus</v-icon>
              {{ $t('client.add-as-admin') }}</v-btn
            >
          </v-col>
        </template>
      </v-row>
    </v-form>
  </v-col>
</template>

<script lang="ts">
import AddExistingAsAdmin from './AddExistingAsAdmin.vue';
import { Client, UserDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';

@Component({
  components: { AddExistingAsAdmin },
  mixins: [validationMixin],
  validations: {
    adminFirstname: {
      required,
    },
    adminLastname: {
      required,
    },
    adminEmail: {
      required,
      email,
    },
    adminPassword: {
      required,
      minLength: minLength(8),
    },
    confirmPassword: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs('adminPassword'),
    },
  },
})
export default class AddAdmin extends Vue {
  @Prop()
  adminIds!: string[];

  @Prop()
  schoolId!: string;

  @Prop()
  clientType!: Client;

  clientModule: ClientModule = getModule(ClientModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  admins: UserDto[] = [];

  adminFirstname: string | null = null;
  adminLastname: string | null = null;
  adminPassword: string | null = null;
  adminEmail: string | null = null;
  email: string | null = null;
  confirmPassword: string | null = null;

  chosenId: string | null = null;

  showPassword = false;
  showConfirmPassword = false;
  loadingAdminUpdate = false;
  loadingAdminRemove = false;
  loadingNewAdmin = false;

  get hasNoAdmin(): boolean {
    return this.adminIds?.length === 0;
  }

  get isSchool(): boolean {
    return this.clientType === Client.school;
  }

  async fetchAdmins() {
    try {
      this.admins = await this.clientModule.fetchAdmins({
        schoolId: this.schoolId,
        adminIds: this.adminIds!,
      });
    } catch (error) {
      console.log(error);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('client.snackbar.admin.fetch').toString(),
        isSuccess: false,
      });
    }
  }

  async mounted() {
    if (!this.hasNoAdmin) {
      await this.fetchAdmins();
    }
  }

  @Watch('adminIds')
  async onAdminIdsChange() {
    if (!this.hasNoAdmin) {
      this.admins = [];
      await this.fetchAdmins();
    }

    if (this.hasNoAdmin) {
      this.admins = [];
    }
  }

  async addAdmin() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      try {
        this.loadingNewAdmin = true;
        await this.clientModule.addNewAdmin({
          schoolId: this.schoolId,
          adminDto: {
            username: this.adminEmail!,
            firstname: this.adminFirstname!,
            lastname: this.adminLastname!,
            email: this.adminEmail!,
            password: this.adminPassword!,
            schoolId: this.schoolId,
            clientType: this.clientType,
          },
        });
        this.onUpdate();
        this.clear();

        this.snackbarModule.showMessage({
          message: this.$i18n.t('client.snackbar.admin.add').toString(),
          isSuccess: true,
        });
      } catch (error: any) {
        if (error?.response?.status === 409) {
          this.snackbarModule.showMessage({
            message: this.$i18n
              .t('client.snackbar.admin.emailConflict')
              .toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.admin.addError').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loadingNewAdmin = false;
      }
    }
  }

  async addOrRemoveAsAdmin(kcId: string) {
    try {
      this.loadingAdminUpdate = true;
      this.chosenId = kcId;
      await this.clientModule.addOrRemoveAsAdmin({
        schoolId: this.schoolId,
        adminDto: { keycloakId: kcId },
      });
      this.onUpdate();
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('client.snackbar.admin.remove').toString(),
        isSuccess: false,
      });
    } finally {
      this.chosenId = null;
      this.loadingAdminUpdate = false;
    }
  }

  async removeFromSchool(kcId: string) {
    try {
      this.loadingAdminRemove = true;
      this.chosenId = kcId;
      await this.clientModule.addOrRemoveAsAdmin({
        schoolId: this.schoolId,
        adminDto: { keycloakId: kcId },
      });
      await this.clientModule.deleteAdmin({
        schoolId: this.schoolId,
        id: kcId,
      });
      this.onUpdate();
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('client.snackbar.admin.remove').toString(),
        isSuccess: false,
      });
    } finally {
      this.chosenId = null;
      this.loadingAdminRemove = false;
    }
  }

  clear() {
    this.adminFirstname = null;
    this.adminLastname = null;
    this.adminPassword = null;
    this.adminEmail = null;
    this.confirmPassword = null;
    this.showPassword = false;
    this.showConfirmPassword = false;
    this.$v.$reset();
  }

  @Emit()
  async onUpdate() {
    await this.clientModule.fetchAllClients();
    return true;
  }

  get adminFirstnameErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminFirstname').toString();
    if (!this.$v.adminFirstname.$dirty) return errors;
    !this.$v.adminFirstname.required && errors.push(requiredError);
    return errors;
  }
  get adminLastnameErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminLastname').toString();
    if (!this.$v.adminLastname.$dirty) return errors;
    !this.$v.adminLastname.required && errors.push(requiredError);
    return errors;
  }
  get adminEmailErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminEmail').toString();
    const emailError = this.$t('client.form.invalidEmail').toString();
    if (!this.$v.adminEmail.$dirty) return errors;
    !this.$v.adminEmail.email && errors.push(emailError);
    !this.$v.adminEmail.required && errors.push(requiredError);
    return errors;
  }

  get adminPasswordErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminPassword').toString();
    const minLength = this.$t('client.form.minLength').toString();
    if (!this.$v.adminPassword.$dirty) return errors;
    !this.$v.adminPassword.required && errors.push(requiredError);
    !this.$v.adminPassword.minLength && errors.push(minLength);
    return errors;
  }

  get confirmPasswordErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.confirmPassword').toString();
    const noMatch = this.$t('client.form.passwordNoMatch').toString();
    if (!this.$v.confirmPassword.$dirty) return errors;
    !this.$v.confirmPassword.sameAsPassword && errors.push(noMatch);
    !this.$v.confirmPassword.required && errors.push(requiredError);
    return errors;
  }
}
</script>

<style lang="scss" scoped>
.admins-row {
  width: 100%;
}
</style>
