<template>
  <v-dialog v-model="show" max-width="600px" persistent>
    <v-card>
      <v-card-title class="justify-center">
        <p class="text-h5 font-weight-bold">{{ $t('attention') }}!</p>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-column justify-center align-center my-3">
          <template v-if="!isClientToDelete">
            <p class="body-1">
              {{ $t('client.snackbar.delete.students.question') }}
            </p>
            <p class="text-h6 font-weight-bold">{{ name }}</p>
            <p class="text-h6">
              {{ $t('client.numOfStudents') }}: {{ numOfStudents }}
            </p>
          </template>
          <template v-else>
            <p class="body-1 mb-5">
              {{ $t('client.snackbar.delete.client.question') }}
            </p>
            <p class="text-h6 font-weight-bold">{{ name }}</p>
            <p class="body-1">
              {{ $t('client.snackbar.delete.client.info') }}
            </p>
          </template>
          <p class="body-1 text-uppercase mt-5">
            {{ $t('point-of-no-return') }}
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          data-testid="cancel-delete-client-button"
          color="blue darken-1"
          text
          @click="cancel"
        >
          <p class="ma-0">{{ $t('cancel') }}</p>
        </v-btn>
        <v-btn
          v-if="!isClientToDelete"
          data-testid="delete-students-button"
          color="error"
          :loading="loadingStudents"
          @click="deleteStudents"
        >
          <p class="ma-0">{{ $t('delete') }}</p>
        </v-btn>
        <v-btn
          v-else
          data-testid="delete-client-button"
          color="error"
          :loading="loadingClient"
          @click="deleteClient"
        >
          <p class="ma-0">{{ $t('delete') }}</p>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { GetSchoolDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component
export default class ClientDeleteDialog extends Vue {
  @Prop() show!: boolean;
  @Prop() client?: GetSchoolDto;
  @Prop() type!: 'client' | 'students';

  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  clientModule: ClientModule = getModule(ClientModule, this.$store);

  loadingStudents = false;
  loadingClient = false;

  get isClientToDelete(): boolean {
    return this.type === 'client';
  }

  get name(): string {
    return this.client?.schoolName ?? '';
  }

  get numOfStudents(): string {
    const num = this.client?.classes.reduce(
      (acc, curr) => acc + curr.students.length,
      0,
    );
    return num?.toString() ?? 'Kundendaten nicht gefunden';
  }

  async deleteStudents() {
    if (!this.client) return;
    try {
      this.loadingStudents = true;

      await this.clientModule.deleteStudents(this.client.schoolId);
      this.snackbarModule.showMessage({
        message: this.$i18n
          .t('client.snackbar.delete.students.success')
          .toString(),
        isSuccess: true,
      });
      this.deleted();
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n
          .t('client.snackbar.delete.students.error')
          .toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingStudents = false;
    }
  }

  async deleteClient() {
    if (!this.client) return;
    try {
      this.loadingClient = true;
      await this.clientModule.deleteClient(this.client.keycloakId);
      this.snackbarModule.showMessage({
        message: this.$i18n
          .t('client.snackbar.delete.client.success')
          .toString(),
        isSuccess: true,
      });
      await this.clientModule.fetchAllClients();
      setTimeout(() => this.$router.push({ name: 'groups' }), 1500);
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('client.snackbar.delete.client.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingClient = false;
    }
  }

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleted() {}

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cancel() {}
}
</script>

<style scoped lang="scss"></style>
