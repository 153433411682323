<template>
  <v-container>
    <v-col class="editClientForm my-5" cols="12">
      <v-card class="pa-2">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn text @click="goToGroupTable">
            <p class="ma-0 mr-2 mb-n1">{{ $t('close') }}</p>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-form @submit.prevent="editClient">
          <v-row class="justify-start">
            <v-col cols="12" class="d-flex justify-center">
              <v-progress-linear
                v-if="loadingClient"
                indeterminate
                color="primary"
              ></v-progress-linear>
              <p v-else class="text-h6">
                {{ $t('client.edit') }}: {{ client?.schoolName }}
              </p>
            </v-col>
            <v-col cols="12">
              <v-row class="ma-0" justify="center">
                <v-radio-group
                  row
                  v-model="editingClient.clientType"
                  class="ml-5 justify-self-center"
                  data-testid="edit-clientType"
                  disabled
                >
                  <v-radio
                    v-for="(t, i) in types"
                    :key="i"
                    :label="t.name"
                    :value="t.type"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </v-col>
            <v-col class="d-flex justify-end" cols="12">
              <v-checkbox
                class="ma-0"
                v-model="editingClient.isTutorialOnly"
                :label="$t('superadmin.isTutorialOnly')"
                hide-details
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editingClient.schoolName"
                data-testid="edit-schoolName"
                :error-messages="schoolNameErrors"
                :label="$t('client.property.name')"
                dense
                outlined
                required
                clearable
                @input="$v.editingClient.schoolName.$touch()"
                @blur="$v.editingClient.schoolName.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="editingClient.slug"
                data-testid="edit-slug"
                :error-messages="slugErrors"
                :label="$t('client.property.slug')"
                dense
                outlined
                required
                clearable
                @input="$v.editingClient.slug.$touch()"
                @blur="$v.editingClient.slug.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="editingClient.schoolId"
                data-testid="edit-schoolId"
                :error-messages="schoolIdErrors"
                :label="$t('client.property.id')"
                dense
                outlined
                type="number"
                disabled
                required
                @input="$v.editingClient.schoolId.$touch()"
                @blur="$v.editingClient.schoolId.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="editingClient.street"
                data-testid="edit-street"
                :error-messages="streetErrors"
                :label="$t('client.property.street')"
                dense
                outlined
                required
                clearable
                @input="$v.editingClient.street.$touch()"
                @blur="$v.editingClient.street.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="editingClient.postcode"
                data-testid="edit-postcode"
                :error-messages="postcodeErrors"
                :label="$t('client.property.postcode')"
                dense
                outlined
                min="1000"
                type="number"
                clearable
                required
                @input="$v.editingClient.postcode.$touch()"
                @blur="$v.editingClient.postcode.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="editingClient.city"
                data-testid="edit-city"
                :error-messages="cityErrors"
                :label="$t('client.property.city')"
                dense
                outlined
                clearable
                required
                @input="$v.editingClient.city.$touch()"
                @blur="$v.editingClient.city.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="editingClient.country"
                data-testid="edit-country"
                :items="countries"
                :label="$t('client.property.country')"
                :search-input="search"
                autocomplete="new-password"
                class="py-0"
                outlined
                clearable
                dense
                background-color="white"
                item-text="name"
                item-value="code"
                :validate-on-blur="false"
                :error-messages="countryErrors"
                @input="$v.editingClient.country.$touch()"
                @blur="$v.editingClient.country.$touch()"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="editingClient.licenseUntil"
                datatestid="edit-licenseUntil"
                v-mask="'##.##.####'"
                :validate-on-blur="false"
                :label="`${$t('client.property.licenseUntil')}`"
                name="licenseUntil"
                :error-messages="licenseUntilErrors"
                outlined
                clearable
                dense
                prepend-inner-icon="mdi-calendar-month"
                @input="$v.editingClient.licenseUntil.$touch()"
                @blur="$v.editingClient.licenseUntil.$touch()"
              ></v-text-field>
            </v-col>
            <v-col v-if="isSchoolClient" cols="12" md="6">
              <v-text-field
                v-model="editingClient.classLimit"
                data-testid="edit-classLimit"
                :error-messages="classLimitErrors"
                :label="$t('client.property.classLimit')"
                dense
                outlined
                min="8"
                type="number"
                clearable
                required
                @input="$v.editingClient.classLimit.$touch()"
                @blur="$v.editingClient.classLimit.$touch()"
              ></v-text-field>
            </v-col>

            <v-col v-if="isTherapyClient" cols="12" md="6">
              <v-text-field
                v-model="editingClient.validTests"
                data-testid="validTests"
                :error-messages="validTestsErrors"
                :label="$t('client.property.validTests')"
                dense
                outlined
                type="number"
                clearable
                required
                @input="$v.editingClient.validTests.$touch()"
                @blur="$v.editingClient.validTests.$touch()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="d-flex justify-end my-6">
              <v-btn
                data-testid="save-client-button"
                color="primary"
                :loading="loading"
                type="submit"
              >
                <v-icon>mdi-content-save</v-icon>
                <p class="ma-0 mb-n1 ml-2">
                  {{ $t('client.save-edit') }}
                </p>
              </v-btn>
            </v-col>
            <AddAdmin
              :school-id="editingClient.schoolId"
              :admin-ids="editingClient.admins"
              :client-type="editingClient.clientType"
              @on-update="fetchCurrentClient"
            />

            <ManageClasses
              v-if="isSchoolClient && editingClient.schoolId"
              class="my-8"
              :schoolId="editingClient.schoolId"
              :classes="editingClient.classes"
              :classLimit="editingClient.classLimit"
              @on-update="fetchCurrentClient"
            />

            <v-col
              cols="12"
              class="d-flex mb-5 justify-space-between align-center"
            >
              <p class="text-h6">
                {{ $t('client.students') }}: {{ getNumOfStudents() }}
              </p>
              <v-btn
                :loading="loadingStudents"
                data-testid="delete-students-button"
                dark
                color="black"
                @click="openDeleteDialog('students')"
              >
                <v-icon>mdi-delete</v-icon>
                <p class="ma-0 ml-2 mb-n1">
                  {{ $t('client.delete-students') }}
                </p>
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-space-between my-6">
              <v-btn
                data-testid="delete-client-button"
                dark
                block
                color="black"
                @click="openDeleteDialog('client')"
              >
                <v-icon>mdi-delete</v-icon>
                <p class="ma-0 mb-n1 ml-2">{{ $t('client.delete') }}</p>
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex py-0 mt-9">
              <p class="text-h6">{{ $t('client.docs') }}</p>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-wrap justify-center align-center align-md-start mb-3"
            >
              <v-col cols="12" sm="12" md="12" lg="4">
                <v-btn
                  block
                  data-testid="download-avv-button"
                  :loading="loadingAvv"
                  @click="getAvvPdf"
                  color="primary"
                  ><v-icon class="mr-2">mdi-download</v-icon
                  >{{ $t('client.avv') }}</v-btn
                >
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4" class="my-7 my-lg-0">
                <v-form @submit.prevent="getAdminManual">
                  <v-text-field
                    v-model="editingClient.defaultAdminPassword"
                    data-testid="manual-admin-password"
                    type="text"
                    :label="$t('client.property.pdfPassword')"
                    dense
                    name="password"
                    autocomplete="new-password"
                    outlined
                    clearable
                    required
                  ></v-text-field>

                  <v-btn
                    block
                    data-testid="download-manual-admin-button"
                    :loading="loadingManual"
                    type="submit"
                    color="primary"
                    ><v-icon class="mr-2">mdi-book-arrow-down</v-icon
                    >{{ $t('client.client-manual-admin') }}</v-btn
                  >
                </v-form>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <v-btn
                  block
                  data-testid="download-manual-others-button"
                  :loading="loadingTTManual"
                  @click="getTeacherTherapistManual"
                  color="primary"
                  ><v-icon class="mr-2">mdi-download</v-icon>
                  {{
                    isTherapyClient
                      ? $t('client.client-manual-therapist')
                      : $t('client.client-manual-teacher')
                  }}</v-btn
                >
              </v-col>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-col>
    <ClientDeleteDialog
      :show="showDeleteDialog"
      :client="client"
      :type="deleteType"
      @deleted="closeAndRefetch"
      @cancel="closeDeleteDialog"
    />
    <AdminSnackbar />
  </v-container>
</template>

<script lang="ts">
import AddAdmin from './manage-admin/AddAdmin.vue';
import {
  Client,
  GetSchoolClassDto,
  GetSchoolDto,
  UpdateSchoolDto,
  UserDto,
} from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import dayjs from 'dayjs';
import * as allCountries from 'i18n-iso-countries';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import {
  maxLength,
  minValue,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import AdminSnackbar from '../admin/base/AdminSnackbar.vue';
import ClientDeleteDialog from './ClientDeleteDialog.vue';
import ManageClasses from './school-classes/Manage-Classes.vue';
// eslint-disable-next-line @typescript-eslint/no-var-requires
allCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const hasNoLeadingZero = (value: any) => {
  if (!value) return true;
  return value[0] !== '0';
};

export const isValidDate = (value: any) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  // https://stackoverflow.com/questions/51224/regular-expression-to-match-valid-dates comment: Harendra Chauhan
  return /(((0[1-9]|[12][0-9]|3[01])([.])(0[13578]|10|12)([.])(\d{4}))|(([0][1-9]|[12][0-9]|30)([.])(0[469]|11)([.])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([.])(02)([.])(\d{4}))|((29)(\/)(02)([.])([02468][048]00))|((29)([.])(02)([.])([13579][26]00))|((29)([.])(02)([.])([0-9][0-9][0][48]))|((29)([.])(02)([.])([0-9][0-9][2468][048]))|((29)([.])(02)([.])([0-9][0-9][13579][26])))/.test(
    value,
  );
};

@Component({
  components: { AdminSnackbar, ClientDeleteDialog, ManageClasses, AddAdmin },
  mixins: [validationMixin],
  validations: {
    editingClient: {
      clientType: {
        required,
      },
      schoolId: {
        required,
        hasNoLeadingZero,
      },
      schoolName: {
        required,
      },
      slug: {
        required,
      },
      postcode: {
        required,
        minValue: minValue(1000),
      },
      street: {
        required,
      },
      city: {
        required,
      },
      country: {
        required,
      },
      licenseUntil: {
        required,
        isValidDate,
        maxLength: maxLength(10),
      },
      classLimit: {
        required: requiredIf(function (this: any) {
          return this.clientType === Client.school;
        }),
        minValue: minValue(8),
      },
      validTests: {
        required: requiredIf(function (this: any) {
          return this.clientType === Client.therapy;
        }),
      },
    },
  },
})
export default class AddEditGroup extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  clientModule: ClientModule = getModule(ClientModule, this.$store);

  defaultClasses = [
    '2.Schulstufe',
    '3.Schulstufe',
    '4.Schulstufe',
    '5.Schulstufe',
    '6.Schulstufe',
  ];

  types = [
    { name: 'Schule', type: Client.school },
    { name: 'Therapie', type: Client.therapy },
    { name: 'Eltern', type: Client.parent },
  ];

  homeCountry = 'Österreich';
  firstCountries = ['Deutschland', 'Schweiz'];
  client: GetSchoolDto | null = null;

  countrySort = (a: string, b: string) => {
    if (a === this.homeCountry) {
      return -1;
    } else if (b === this.homeCountry) {
      return 1;
    }
    if (this.firstCountries.includes(a) && this.firstCountries.includes(b)) {
      return a.localeCompare(b);
    } else if (this.firstCountries.includes(a)) {
      return -1;
    } else if (this.firstCountries.includes(b)) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  };

  countries = Object.values(allCountries.getNames('de')).sort(this.countrySort);
  search = '';

  id: string | null = null;

  loading = false;
  loadingClient = false;
  loadingAvv = false;
  loadingManual = false;
  loadingTTManual = false;
  loadingStudents = false;
  showDeleteDialog = false;
  showPassword = false;
  showConfirmPassword = false;

  deleteType: 'client' | 'students' | null = null;

  editingClient = {
    schoolId: null as string | null,
    schoolName: null as string | null,
    street: null as string | null,
    postcode: null as string | null,
    city: null as string | null,
    country: null as string | null,
    url: null as string | null,
    slug: null as string | null,
    clientType: Client.school,
    licenseUntil: null as string | null,
    isTutorialOnly: false,
    admins: [] as string[],
    classes: [] as GetSchoolClassDto[],
    validTests: null as number | null,
    classLimit: null as number | null,
    defaultAdminPassword: null as string | null,
  };

  admin: UserDto | null = null;

  @Watch('clientType')
  changedType(current: any) {
    if (!this.isEdit) {
      // TODO check if correct
      if (current !== Client.school) {
        // this.classes = this.defaultClasses.join(',');
        this.editingClient.classes = [];
      } else {
        this.editingClient.classes = [];
      }
    }
  }

  get isTherapyClient(): boolean {
    return this.editingClient.clientType === Client.therapy;
  }

  get isSchoolClient(): boolean {
    return this.editingClient.clientType === Client.school;
  }

  get isEdit(): boolean {
    return this.$route.params.id !== undefined;
  }

  goToGroupTable() {
    this.$router.push({ name: 'groups' });
  }

  getNumOfStudents() {
    return this.editingClient.classes?.reduce(
      (acc, curr) => acc + curr.students.length,
      0,
    );
  }

  openDeleteDialog(type: 'client' | 'students') {
    this.deleteType = type;
    this.showDeleteDialog = true;
  }

  closeDeleteDialog() {
    this.deleteType = null;
    this.showDeleteDialog = false;
  }

  async closeAndRefetch() {
    await this.fetchCurrentClient();
    this.closeDeleteDialog();
  }

  async fetchCurrentClient() {
    try {
      this.id = this.$route.params.id;
      this.loadingClient = true;
      await this.clientModule.fetchClient(this.id);
      this.client = this.clientModule.currentClient;
      this.editingClient.clientType = this.client.clientType as Client;
      this.editingClient.schoolId = this.client.schoolId;
      this.editingClient.schoolName = this.client.schoolName
        ? this.client.schoolName
        : null;
      this.editingClient.slug = this.client.slug ? this.client.slug : null;
      this.editingClient.street = this.client.street
        ? this.client.street
        : null;
      this.editingClient.postcode = this.client.postcode
        ? this.client.postcode
        : null;
      this.editingClient.city = this.client.city ? this.client.city : null;
      this.editingClient.country = this.client.country
        ? allCountries.getName(this.client.country!, 'de')!
        : null;
      this.editingClient.licenseUntil = this.client.licenseUntil
        ? dayjs(this.client.licenseUntil).format('DD.MM.YYYY')
        : null;
      this.editingClient.classes = this.client.classes
        ? this.client.classes
        : [];
      this.editingClient.validTests =
        this.client.validTests || this.client.validTests === 0
          ? this.client.validTests
          : null;
      this.editingClient.classLimit = this.client.classLimit
        ? this.client.classLimit
        : 8;
      this.editingClient.admins = this.client.admins;
      this.editingClient.isTutorialOnly = this.client.isTutorialOnly;
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('client.snackbar.fetch.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingClient = false;
    }
  }

  async mounted() {
    await this.fetchCurrentClient();
  }

  async editClient() {
    this.$v.editingClient.$touch();

    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        const client: UpdateSchoolDto = {
          clientType: this.editingClient.clientType,
          schoolId: this.editingClient.schoolId!,
          keycloakId: this.client!.keycloakId,
          schoolName: this.editingClient.schoolName!,
          street: this.editingClient.street!,
          postcode: this.editingClient.postcode!,
          city: this.editingClient.city!,
          country: allCountries.getAlpha2Code(
            this.editingClient.country!,
            'de',
          )!,
          licenseUntil: this.editingClient
            .licenseUntil!.split('.')
            .reverse()
            .join('-'),
          validTests: this.editingClient.validTests
            ? +this.editingClient.validTests
            : undefined,
          slug: this.editingClient.slug!,
          url: `${window.location.origin}/#/${this.editingClient.slug!}`,
          isTutorialOnly: this.editingClient.isTutorialOnly,
          classLimit: this.editingClient.classLimit
            ? +this.editingClient.classLimit
            : undefined,
        };
        await this.clientModule.updateClient({
          groupId: this.editingClient.schoolId!,
          client: client,
        });
        this.snackbarModule.showMessage({
          message: this.$i18n.t('client.snackbar.edit.success').toString(),
          isSuccess: true,
        });
        await this.fetchCurrentClient();
        await this.clientModule.fetchAllClients();
      } catch (e: any) {
        const error = e.response?.data?.message;

        if (error.includes('School with this slug already exists')) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.add.slugError').toString(),
            isSuccess: false,
          });
        } else if (error.includes('Admin Email already registered')) {
          this.snackbarModule.showMessage({
            message: this.$i18n
              .t('client.snackbar.add.adminEmailError')
              .toString(),
            isSuccess: false,
          });
        } else if (error.includes('could not create admin for')) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.add.adminError').toString(),
            isSuccess: false,
          });
          await this.clientModule.fetchAllClients();
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.edit.error').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  async getAvvPdf() {
    try {
      this.loadingAvv = true;
      await this.clientModule.downloadAvv(this.client!);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingAvv = false;
    }
  }

  async getAdminManual() {
    try {
      this.loadingManual = true;
      if (!this.editingClient.defaultAdminPassword) {
        throw new Error('Password must not be null');
      }
      await this.clientModule.downloadAdminManual({
        client: this.client!,
        code: this.editingClient.defaultAdminPassword,
      });
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.success').toString(),
        isSuccess: true,
      });
      this.editingClient.defaultAdminPassword = null;
    } catch (error: any) {
      console.log(error);
      if (error.message === 'Password must not be null') {
        this.snackbarModule.showMessage({
          message: this.$i18n.t('client.snackbar.pdf.password').toString(),
          isSuccess: false,
        });
      } else {
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.pdf.error').toString(),
          isSuccess: false,
        });
      }
    } finally {
      this.loadingManual = false;
    }
  }

  async getTeacherTherapistManual() {
    try {
      this.loadingTTManual = true;
      await this.clientModule.downloadTeacherTherapistManual(this.client!);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.success').toString(),
        isSuccess: true,
      });
      this.editingClient.defaultAdminPassword = null;
    } catch (error: any) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingTTManual = false;
    }
  }

  get schoolNameErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.name').toString();
    if (!this.$v.editingClient.schoolName?.$dirty) return errors;
    !this.$v.editingClient.schoolName.required && errors.push(requiredError);
    return errors;
  }

  get schoolIdErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.id').toString();
    const zeroError = this.$t('client.form.zero').toString();
    if (!this.$v.editingClient.schoolId?.$dirty) return errors;
    !this.$v.editingClient.schoolId.hasNoLeadingZero && errors.push(zeroError);
    !this.$v.editingClient.schoolId.required && errors.push(requiredError);
    return errors;
  }

  get streetErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.street').toString();
    if (!this.$v.editingClient.street?.$dirty) return errors;
    !this.$v.editingClient.street.required && errors.push(requiredError);
    return errors;
  }

  get postcodeErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.postcode').toString();
    const minValueError = this.$t('client.form.minValue').toString();
    if (!this.$v.editingClient.postcode?.$dirty) return errors;
    !this.$v.editingClient.postcode.minValue && errors.push(minValueError);
    !this.$v.editingClient.postcode.required && errors.push(requiredError);
    return errors;
  }

  get classLimitErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.classLimit').toString();
    const minValueError = this.$t('client.form.classLimitMin').toString();
    if (!this.$v.editingClient.classLimit?.$dirty) return errors;
    !this.$v.editingClient.classLimit.minValue && errors.push(minValueError);
    !this.$v.editingClient.classLimit.required && errors.push(requiredError);
    return errors;
  }

  get cityErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.city').toString();
    if (!this.$v.editingClient.city?.$dirty) return errors;
    !this.$v.editingClient.city.required && errors.push(requiredError);
    return errors;
  }

  get countryErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.country').toString();
    if (!this.$v.editingClient.country?.$dirty) return errors;
    !this.$v.editingClient.country.required && errors.push(requiredError);
    return errors;
  }
  get licenseUntilErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.licenseUntil').toString();
    const validDateError = this.$t('client.form.invalidDate').toString();
    const maxLengthError = this.$t('client.form.maxLength').toString();
    if (!this.$v.editingClient.licenseUntil?.$dirty) return errors;
    !this.$v.editingClient.licenseUntil.maxLength &&
      errors.push(maxLengthError);
    !this.$v.editingClient.licenseUntil.isValidDate &&
      errors.push(validDateError);
    !this.$v.editingClient.licenseUntil.required && errors.push(requiredError);
    return errors;
  }

  get classesErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.classes').toString();
    if (!this.$v.editingClient.classes?.$dirty) return errors;
    !this.$v.editingClient.classes.required && errors.push(requiredError);
    return errors;
  }

  get validTestsErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.validTests').toString();
    if (!this.$v.editingClient.validTests?.$dirty) return errors;
    !this.$v.editingClient.validTests.required && errors.push(requiredError);
    return errors;
  }

  get adminPasswordErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminPassword').toString();
    const minLength = this.$t('client.form.minLength').toString();
    if (!this.$v.editingClient.adminPassword?.$dirty) return errors;
    !this.$v.editingClient.adminPassword.required && errors.push(requiredError);
    !this.$v.editingClient.adminPassword.minLength && errors.push(minLength);
    return errors;
  }

  get confirmPasswordErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.confirmPassword').toString();
    const noMatch = this.$t('client.form.passwordNoMatch').toString();
    if (!this.$v.editingClient.confirmPassword?.$dirty) return errors;
    !this.$v.editingClient.confirmPassword.sameAsPassword &&
      errors.push(noMatch);
    !this.$v.editingClient.confirmPassword.required &&
      errors.push(requiredError);
    return errors;
  }

  get slugErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.slug').toString();
    if (!this.$v.editingClient.slug?.$dirty) return errors;
    !this.$v.editingClient.slug.required && errors.push(requiredError);
    return errors;
  }
}
</script>

<style lang="scss" scoped>
.addClientForm {
  min-height: 80vh;
}
</style>
