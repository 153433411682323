<template>
  <v-chip dark color="error">{{ $t('client.property-missing') }}</v-chip>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MissingProperty extends Vue {
 
}
</script>

<style lang="scss" scoped>
// Your SCSS styles go here
</style>
