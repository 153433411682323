<template>
  <v-col class="groupsTable">
    <v-col cols="12" class="d-flex pa-0 pt-4 justify-center white">
      <p class="text-h4">{{ $t('client.management') }}</p>
    </v-col>
    <v-col class="page-tabs d-flex pa-0" cols="12">
      <v-tabs centered class="ma-0">
        <v-tab @click="setRoute('all')">{{ $t('client.all') }}</v-tab>
        <v-tab @click="setRoute('create')">{{ $t('client.create') }}</v-tab>
      </v-tabs>
    </v-col>
    <template v-if="isTablePage">
      <v-col cols="12" class="search-field d-flex justify-space-between pa-0">
        <v-col cols="12" sm="12" lg="4" class="pa-0 mb-7">
          <v-btn color="primary" :loading="loadingCsv" @click="downloadCsv">
            <v-icon>mdi-file-download</v-icon>
            <p class="ma-0 ml-2 mb-n1">{{ $t('superadmin.csv') }}</p>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12" lg="4" class="pa-0 mb-7">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="clients"
        sort-by="grade"
        :search="search"
        id="clients-table"
        class=""
        :locale="'de-GE'"
        :footer-props="{
          itemsPerPageText: 'Zeilen pro Seite',
          pageText: '{0}-{1} von {2}',
          itemsPerPageAllText: 'Alle',
          itemsPerPageOptions: [50, 100],
        }"
        @click:row="goToDetailPage"
      >
        <template v-slot:[`item.licenseUntil`]="{ item }">
          <p class="ma-0">{{ getLicenseDate(item.licenseUntil) }}</p>
        </template>
        <template v-slot:[`item.clientType`]="{ item }">
          <v-chip
            v-if="item.clientType"
            dark
            :color="getTypeColor(item.clientType)"
            >{{ getClientTypeName(item.clientType) }}</v-chip
          >
          <MissingProperty v-else />
        </template>
        <template v-slot:[`item.validTests`]="{ item }">
          <p
            v-if="
              hasValidTestsProperty(item) && isTherapyClient(item.clientType)
            "
            class="ma-0"
          >
            {{ item.validTests }}
          </p>
          <MissingProperty
            v-if="
              !hasValidTestsProperty(item) && isTherapyClient(item.clientType)
            "
          />
        </template>
        <template v-slot:[`item.classes`]="{ item }">
          <p v-if="item.classes" class="ma-0">{{ item.classes?.length }}</p>
        </template>
        <template v-slot:[`item.admins`]="{ item }">
          <p v-if="item.admins?.length > 0" class="ma-0">
            {{ item.admins?.length }}
          </p>
          <MissingProperty v-else />
        </template>
        <template v-slot:[`item.street`]="{ item }">
          <p v-if="item.street" class="ma-0">{{ item.street }}</p>
          <MissingProperty v-else />
        </template>
        <template v-slot:[`item.postcode`]="{ item }">
          <p v-if="item.postcode" class="ma-0">{{ item.postcode }}</p>
          <MissingProperty v-else />
        </template>
        <template v-slot:[`item.city`]="{ item }">
          <p v-if="item.city" class="ma-0">{{ item.city }}</p>
          <MissingProperty v-else />
        </template>
        <template v-slot:[`item.country`]="{ item }">
          <p v-if="item.country" class="ma-0">{{ item.country }}</p>
          <MissingProperty v-else />
        </template>
        <template v-slot:[`item.slug`]="{ item }">
          <p v-if="item.slug" class="ma-0">{{ item.slug }}</p>
          <MissingProperty v-else />
        </template>
        <template v-slot:[`item.url`]="{ item }">
          <p v-if="item.url" class="ma-0">{{ item.url }}</p>
          <MissingProperty v-else />
        </template>
      </v-data-table>
    </template>

    <AddGroup v-if="isCreatePage" />
    <EditGroup v-if="isDetailPage" />
    <AdminSnackbar />
  </v-col>
</template>

<script lang="ts">
import { Client, GetSchoolDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import dayjs from 'dayjs';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import AdminSnackbar from '../admin/base/AdminSnackbar.vue';
import AddGroup from './AddGroup.vue';
import EditGroup from './EditGroup.vue';
import ImportToMongoButton from './ImportToMongoButton.vue';
import MissingProperty from './MissingProperty.vue';

@Component({
  components: {
    AddGroup,
    EditGroup,
    MissingProperty,
    ImportToMongoButton,
    AdminSnackbar,
  },
})
export default class GroupTable extends Vue {
  clientModule: ClientModule = getModule(ClientModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  showCreateClient = false;
  loadingCsv = false;
  search = '';
  headers = [
    { text: 'ID', value: 'schoolId', align: 'center' },
    { text: 'Name', value: 'schoolName' },
    { text: 'slug', value: 'slug' },
    { text: 'Lizenz bis', value: 'licenseUntil' },
    { text: 'Klassen', value: 'classes' },
    { text: 'Klassenlimit', value: 'classLimit' },
    { text: 'Offene Tests', value: 'validTests' },
    {
      text: 'Typ',
      value: 'clientType',
    },
    { text: 'Admins', value: 'admins' },
    { text: 'Straße', value: 'street' },
    { text: 'PLZ', value: 'postcode' },
    { text: 'Ort', value: 'city' },
    { text: 'Land', value: 'country' },
    { text: 'URL', value: 'url' },
  ];
  chosenId: string | null = null;

  get clients(): GetSchoolDto[] {
    return this.clientModule.allClients;
  }

  get isTablePage(): boolean {
    return this.$route.name === 'groups';
  }

  get isCreatePage(): boolean {
    return this.$route.name === 'groups-create';
  }

  get isDetailPage(): boolean {
    return this.$route.name === 'groups-detail';
  }

  hasValidTestsProperty(item: GetSchoolDto): boolean {
    return !!item.validTests || item.validTests === 0;
  }

  setRoute(route: 'all' | 'detail' | 'create') {
    switch (route) {
      case 'all':
        this.chosenId = null;
        this.$router.push({ name: 'groups' });
        break;
      case 'detail':
        this.$router.push({
          name: 'groups-detail',
          params: { id: this.chosenId! },
        });
        break;
      case 'create':
        this.chosenId = null;
        this.$router.push({ name: 'groups-create' });
        break;
      default:
        this.chosenId = null;
        this.$router.push({ name: 'groups' });
        break;
    }
  }

  isTherapyClient(type: string): boolean {
    return type === Client.therapy;
  }

  getLicenseDate(licenseUntil: string): string {
    return dayjs(licenseUntil).format('DD.MM.YYYY');
  }

  getClientTypeName(clientType: string): string {
    switch (clientType) {
      case Client.school:
        return 'Schule';
      case Client.therapy:
        return 'Therapie';
      case Client.parent:
        return 'Eltern';
      default:
        return 'Superadmin';
    }
  }

  getTypeColor(type: string) {
    switch (type) {
      case Client.school:
        return 'blue darken-4';
      case Client.therapy:
        return 'green darken-2';
      case Client.parent:
        return 'red darken-2';
      default:
        return 'grey';
    }
  }

  async fetchAllClients() {
    await this.clientModule.fetchAllClients();
  }

  async mounted() {
    await this.fetchAllClients();
  }

  async downloadCsv() {
    try {
      this.loadingCsv = true;
      await this.clientModule.downloadCsv();
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.csv.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.csv.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingCsv = false;
    }
  }

  goToDetailPage(item: GetSchoolDto) {
    this.chosenId = item.schoolId.toString();
    this.setRoute('detail');
  }

  openCreate() {
    this.showCreateClient = true;
  }

  closeCreate() {
    this.showCreateClient = false;
  }
}
</script>

<style lang="scss" scoped>
.schoolColor {
  color: blue;
}

.therapyColor {
  color: green;
}

.parentColor {
  color: orangered;
}
</style>

<style lang="scss">
#clients-table {
  min-height: 80vh;
  tr {
    cursor: pointer;
  }
}

.search-field {
  background-color: white;
}
</style>
